<template>
<main>
  <div class="h-screen items-center animate__animated animate__fadeIn">
      <div class=" bg-fixed bg-cover w-full h-full notfoundpg">   
        <div class="container m-auto animate__animated animate__fadeIn animate__delay-1s">  
          <div class="container items-center absolute bottom-0 m-auto mb-20 lg:mb-0">
            <h1 class="text-4xl md:text-6xl lg:text-9xl text-center bg-clip-text text-white tracking-wider uppercase">PAGE NOT FOUND</h1>  
            <div class="text-xl text-center font-medium flex justify-center text-white mt-5">
                <router-link to="/"><button class="btn-grad w-auto mb-5 block items-center justify-center uppercase rounded-2xl font-semibold text-white px-10 py-2 hover:shadow-md tracking-widest">Home</button> </router-link>                   
            </div>          
          </div>                                         
        </div>
      </div>           
    </div>

    <div class="bg-white">
      <div class="container m-auto">
        <div class="md:m-auto justify-center py-20">    
        <h1 class="text-center text-4xl font-semibold text-primary tracking-widest uppercase">Get Started Today</h1>   
        <p class="text-center text-2xl font-medium py-8 px-4">We take time to understand your business and customers, and then align our services to deliver remarkable results.</p> 
        <div class="grid m-auto">
          <span class="text-center uppercase bg-blue-50 rounded-xl p-4 px-6 m-auto w-auto text-primary font-semibold tracking-widest cursor-pointer"><a href="mailto:hello@qualon.xyz">hello@qualon.xyz</a></span>
        </div>                    
      </div>               
      </div>
    </div> 
</main>
</template>